import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes } from 'react-router-dom';
import { Route } from 'react-router';

import App from './App';
import { AdminApp } from './pages/Admin/AdminApp';

import './styles/normalize.css';

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="admin/*" element={<AdminApp />} />
            <Route path="/*" element={<App />} />
        </Routes>
    </BrowserRouter>,
    document.getElementById('root')
);
