import Amplify from 'aws-amplify';

export const initAuth = () =>
    fetch('/vars.json')
        .then((data) => data.json())
        .then((vars) => {
            Amplify.configure({
                Auth: {
                    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
                    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

                    // REQUIRED - Amazon Cognito Region
                    region: 'eu-central-1',

                    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
                    // Required only if it's different from Amazon Cognito Region
                    // identityPoolRegion: 'XX-XXXX-X',

                    // OPTIONAL - Amazon Cognito User Pool ID
                    userPoolId: vars.userPoolId,

                    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                    userPoolWebClientId: vars.clientAppId,

                    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
                    mandatorySignIn: false,
                },
            });
        });
