import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';

import { TalkInput } from '../../../../components/TalkInput';
import { addTalk, getTags } from '../../../../utils/api/talk';

export const NewTalk: React.FC = () => {
    const navigate = useNavigate();
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        Auth.currentSession().catch(() => navigate('/admin/login'));
    }, [navigate]);

    useEffect(() => {
        getTags()
            .then((tags) => setTags(tags))
            .catch((err) => console.error(err));
    }, []);

    return (
        <div>
            <TalkInput saveTalk={addTalk} tags={tags} />
        </div>
    );
};
