import React from 'react';
import { ReactComponent as Pizza } from '../../images/Pizza.svg';
import { ReactComponent as Anregungen } from '../../images/Anregungen.svg';
import { ReactComponent as Community } from '../../images/Community.svg';
import { ReactComponent as Technologien } from '../../images/Technologien.svg';

import styles from './index.module.scss';
import { ContentBlock } from '../ContentBlock';
import { Scheme } from '../ContentBlock/contentBlockUtils';

export const LoveTechTalks: React.FC = () => {
    return (
        <ContentBlock title="Darum wirst du unsere TechTalks lieben:" scheme={Scheme.limeGreenWhite}>
            <div className={styles.aboutInfoContainer}>
                <div className={styles.aboutInfoBlock}>
                    <Technologien />
                    Du lernst wegweisende Technologien kennen.
                </div>
                <div className={styles.aboutInfoBlock}>
                    <Community />
                    Du wirst Teil einer lebhaften Tech-Community.
                </div>
                <div className={styles.aboutInfoBlock}>
                    <Anregungen />
                    Du erhältst Anregung und Inspirationen für deine eigenen Projekte.
                </div>
                <div className={styles.aboutInfoBlock}>
                    <Pizza />
                    Du genießt köstliche Pizza und kühle Getränke.
                </div>
            </div>
        </ContentBlock>
    );
};
