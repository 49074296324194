import { useEffect } from 'react';

function useScrollUp() {
    useEffect(() => {
        requestAnimationFrame(() => {
            window.scrollTo(0, 0);
        });
    }, []);
}

export default useScrollUp;
