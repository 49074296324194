import React from 'react';
import { ReactComponent as Cross } from '../../../images/Close.svg';
import classnames from 'classnames';

import styles from './index.module.scss';

export const Select: React.FC<{
    callback: () => void;
    tag: string;
    selected: boolean;
}> = ({ callback, tag, selected }) => {
    return (
        <div onClick={callback} className={classnames({ [styles.show]: selected }, styles.checkbox)}>
            <Cross className={classnames({ [styles.hide]: !selected }, styles.cross)} />
            {tag}
        </div>
    );
};
