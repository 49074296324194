import React from 'react';
import classNames from 'classnames';

import styles from '../Button/index.module.scss';
import { reduceColor, ButtonColors } from '../Button';
import { Link } from 'react-router-dom';

interface ILinkAsButtonProps {
    to: string;
    children: React.ReactNode;
    color: ButtonColors;
    className?: string;
    external?: boolean;
}

export const LinkAsButton: React.FC<ILinkAsButtonProps> = ({ to, children, color, className, external }) => {
    const buttonClassNames = classNames(styles.button, reduceColor(color), className);
    if (external) {
        return (
            <a href={to} className={buttonClassNames} target="_blank" rel="noopener noreferrer nofollow">
                {children}
            </a>
        );
    }
    return (
        <Link to={to} className={buttonClassNames}>
            {children}
        </Link>
    );
};
