import React from 'react';
import { Link, useMatch } from 'react-router-dom';

interface INavLinkProps {
    to: string;
    children: React.ReactNode;
    className: string;
    activeClassName: string;
    onClick?: () => void;
}

export const NavLink: React.FC<INavLinkProps> = ({ to, className, activeClassName, children, onClick }) => {
    const match = useMatch(to);

    return (
        <Link to={to} className={match ? className + ' ' + activeClassName : className} onClick={onClick}>
            {children}
        </Link>
    );
};
