import React from 'react';

import styles from './index.module.scss';

import { Scheme, schemeToBlockSettings } from './contentBlockUtils';
import { MultilineTitle, TitlePosition } from '../MultilineTitle';
import classnames from 'classnames';

interface IContentBlockProps {
    scheme: Scheme;
    title: string;
    children: React.ReactNode;
}

export const ContentBlock: React.FC<IContentBlockProps> = ({ scheme, title, children }) => {
    const settings = schemeToBlockSettings(scheme);

    const containerClassNames = classnames(styles[settings.backgroundClassName], styles.containerClassName);
    return (
        <div className={containerClassNames}>
            <MultilineTitle position={TitlePosition.LEFT} color={settings.titleColor} className={styles.title}>
                {title}
            </MultilineTitle>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
