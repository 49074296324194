import React, { useState, useEffect } from 'react';
import { MultilineTitle, TitleColor, TitlePosition } from '../../components/MultilineTitle';

import styles from './index.module.scss';
// import { PrivacyLink } from '../../components/PrivacyLink';
// import { Button, ButtonColors } from '../../components/Button';
import { Heroimage } from '../../components/Heroimage';
import RegistrationImage from '../../images/heroimages/Heroimage_Anmeldung.jpg';
import { ITalk } from '../../components/TalkInput/model';
import { Newsletter } from '../../components/Newsletter';
import { NextTalk } from '../../components/NextTalk';
import { getNextTalk } from '../../utils/api/talk';
import useScrollUp from '../../utils/useScrollUp';

export const Registration: React.FC = () => {
    const [talk, setTalk] = useState<ITalk>();
    useScrollUp();
    // const [name, setName] = useState('');
    // const [mail, setMail] = useState('');
    // const [privacy, setPrivacy] = useState<boolean>(false);

    // const changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setName(event.currentTarget.value);
    // };

    // const changeMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setMail(event.currentTarget.value);
    // };

    // const togglePrivacy = () => {
    //     setPrivacy(!privacy);
    // };

    // const submit = () => {
    //     if (name !== '' && mail !== '' && privacy === true) {
    //         // TODO: Submit message
    //     } else {
    //         // TODO: Handle Error
    //     }
    // };

    useEffect(() => {
        getNextTalk()
            .then((res) => {
                const nextTalk: ITalk = res.talks !== null && res.talks !== undefined ? res.talks[0] : undefined;
                if (nextTalk) {
                    nextTalk.talkDateTime = new Date(nextTalk.talkDateTime);
                    setTalk(nextTalk);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const registrationHeroimage = <Heroimage image={RegistrationImage} title="Melde dich kostenlos an &" subTitle="komm vorbei!"></Heroimage>;

    if (talk !== undefined && talk !== null) {
        return (
            <div>
                {registrationHeroimage}
                <NextTalk talk={talk} />
                <p className={styles.description}>{talk.description}</p>
                {/* <form className={styles.form}>
                    <div className={styles.textInputs}>
                        <div>
                            <label htmlFor="name">Name</label>
                            <input className={styles.input} type="text" id="name" name="name" value={name} onChange={changeName}></input>
                        </div>
                        <div>
                            <label htmlFor="mail">Mail</label>
                            <input className={styles.input} type="text" id="mail" name="mail" value={mail} onChange={changeMail}></input>
                        </div>
                    </div>
                    <div className={styles.disclaimer}>
                        <input type="checkbox" id="accept" className={styles.checkbox} checked={privacy} onChange={togglePrivacy} />
                        <label htmlFor="accept">
                            Mit dem Absenden dieser Nachricht erklären Sie sich damit einverstanden, dass wir Ihre Daten ausschließlich zu Kontaktzwecken nutzen
                            und speichern. Die Daten können auf Wunsch jederzeit gelöscht, widerrufen, eingesehen, transferiert und berichtigt werden. Nähere
                            Informationen dazu entnehmen Sie bitte unserer <PrivacyLink>Datenschutzerklärung</PrivacyLink>.
                        </label>
                    </div>
                    <Button color={ButtonColors.GRAY} className={styles.centeredButton} onClick={submit}>
                        KOSTENLOS ANMELDEN
                    </Button>
                </form> */}
            </div>
        );
    } else {
        return (
            <div>
                {registrationHeroimage}
                <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.unannounced}>
                    Der nächste Talk steht noch nicht fest.
                </MultilineTitle>
                <Newsletter />
            </div>
        );
    }
};
