import { TitleColor } from '../MultilineTitle';

export enum Scheme {
    grayLimeGreen = 'GRAY_LIME_GREEN',
    grayWhite = 'GRAY_WHITE',
    whiteLimeGreen = 'WHITE_LIME_GREEN',
    whiteGreen = 'WHITE_GREEN',
    whiteGray = 'WHITE_GRAY',
    limeGreenWhite = 'LIME_GREEN_WHITE',
    limeGreenGray = 'LIME_GREEN_GRAY',
}

interface IContentBlock {
    titleColor: TitleColor;
    backgroundClassName: string;
}

export const schemeToBlockSettings = (scheme: Scheme): IContentBlock => {
    switch (scheme) {
        case Scheme.grayLimeGreen:
            return {
                titleColor: TitleColor.LIME_GREEN,
                backgroundClassName: 'bgGray',
            };
        case Scheme.grayWhite:
            return {
                titleColor: TitleColor.WHITE,
                backgroundClassName: 'bgGray',
            };
        case Scheme.whiteLimeGreen:
            return {
                titleColor: TitleColor.LIME_GREEN,
                backgroundClassName: 'bgWhite',
            };
        case Scheme.whiteGreen:
            return {
                titleColor: TitleColor.GREEN,
                backgroundClassName: 'bgWhite',
            };
        case Scheme.whiteGray:
            return {
                titleColor: TitleColor.GRAY,
                backgroundClassName: 'bgWhite',
            };
        case Scheme.limeGreenWhite:
            return {
                titleColor: TitleColor.WHITE,
                backgroundClassName: 'bgGreen',
            };
        case Scheme.limeGreenGray:
            return {
                titleColor: TitleColor.GRAY,
                backgroundClassName: 'bgGreen',
            };
        default:
            return {
                titleColor: TitleColor.LIME_GREEN,
                backgroundClassName: 'bgWhite',
            };
    }
};
