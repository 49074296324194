import React, { useEffect, useState, useRef } from 'react';
import isNil from 'lodash/isNil';

import styles from './index.module.scss';
import { InsightsGrid } from '../../../components/InsightsGrid';
import { deleteInsight, getInsights, uploadInsightImage } from '../../../utils/api/image';

const AdminInsightView: React.FC<{ src: string }> = ({ src }) => (
    <>
        <img src={`/${src}`} alt="the TechTalks" />
        <button className={styles.deleteButton} onClick={() => deleteInsight(src)}>
            Delete
        </button>
    </>
);

export const Insights: React.FC = () => {
    const [insights, setInsights] = useState<{ Key: string }[]>([]);
    const imageRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getInsights(true).then((data) => setInsights(data));
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isNil(imageRef.current) && imageRef.current.files) {
            for (let i = 0; i < imageRef.current.files.length; i++) {
                try {
                    await uploadInsightImage(imageRef.current.files[i]);
                } catch (err) {
                    console.error(err);
                }
            }
            imageRef.current.value = '';
        }
    };

    return (
        <>
            <form className={styles.uploadForm} onSubmit={handleSubmit}>
                <input ref={imageRef} name="image" type="file" accept="image/png, image/jpeg" multiple />
                <input type="submit" />
            </form>
            <InsightsGrid insights={insights} viewComponent={AdminInsightView} />
        </>
    );
};
