export interface ITalk {
    speaker: string[];
    title: string;
    subtitle?: string;
    talkDateTime: Date;
    description: string;
    videoUrl?: string;
    slidesUrl?: string;
    zoomUrl?: string;
    sourceCodeUrl?: string;
    tags?: string[];
    previewImage?: string;
    marketingImage?: string;
    videoThumbnailImage?: string;
    eventUrl?: string;
}

export const emptyTalk: ITalk = {
    title: '',
    speaker: [],
    description: '',
    talkDateTime: new Date(),
};

const TITLE = 'TITLE';
const SUBTITLE = 'SUBTITLE';
const SPEAKERS = 'SPEAKERS';
const DATE_TIME = 'DATE_TIME';
const TAGS = 'TAGS';
const DESCRIPTION = 'DESCRIPTION';
const VIDEO_URL = 'VIDEO_URL';
const SLIDES_URL = 'SLIDES_URL';
const ZOOM_URL = 'ZOOM_URL';
const CODE_URL = 'CODE_URL';
const EVENT_URL = 'EVENT_URL';
export const PREVIEW_IMAGE = 'PREVIEW_IMAGE';
export const MARKETING_IMAGE = 'MARKETING_IMAGE';
export const VIDEO_THUMBNAIL_IMAGE = 'VIDEO_THUMBNAIL_IMAGE';

export { TITLE, SUBTITLE, SPEAKERS, DATE_TIME, TAGS, DESCRIPTION, VIDEO_URL, SLIDES_URL, CODE_URL, EVENT_URL, ZOOM_URL };

interface ITalkTitleChangeAction {
    type: typeof TITLE;
    payload: string;
}

interface ITalkSubtitleChangeAction {
    type: typeof SUBTITLE;
    payload: string;
}

interface ITalkSpeakersChangeAction {
    type: typeof SPEAKERS;
    payload: string;
}

interface ITalkDateTimeChangeAction {
    type: typeof DATE_TIME;
    payload: Date;
}

interface ITalkDescriptionChangeAction {
    type: typeof DESCRIPTION;
    payload: string;
}

interface ITalkTagsChangeAction {
    type: typeof TAGS;
    payload: string[];
}

interface ITalkVideoUrlChangeAction {
    type: typeof VIDEO_URL;
    payload: string;
}

interface ITalkSlidesUrlChangeAction {
    type: typeof SLIDES_URL;
    payload: string;
}

interface ITalkZoomUrlChangeAction {
    type: typeof ZOOM_URL;
    payload: string;
}

interface ITalkCodeUrlChangeAction {
    type: typeof CODE_URL;
    payload: string;
}

interface ITalkEventUrlChangeAction {
    type: typeof EVENT_URL;
    payload: string;
}

interface ITalkMarketingImageChangeAction {
    type: typeof MARKETING_IMAGE;
    payload: string | undefined;
}

interface ITalkVideoThumbnailImageChangeAction {
    type: typeof VIDEO_THUMBNAIL_IMAGE;
    payload: string | undefined;
}

interface ITalkPreviewImageChangeAction {
    type: typeof PREVIEW_IMAGE;
    payload: string | undefined;
}

type TalkFormAction =
    | ITalkTitleChangeAction
    | ITalkSubtitleChangeAction
    | ITalkSpeakersChangeAction
    | ITalkDateTimeChangeAction
    | ITalkDescriptionChangeAction
    | ITalkTagsChangeAction
    | ITalkVideoUrlChangeAction
    | ITalkSlidesUrlChangeAction
    | ITalkZoomUrlChangeAction
    | ITalkCodeUrlChangeAction
    | ITalkEventUrlChangeAction
    | ITalkMarketingImageChangeAction
    | ITalkVideoThumbnailImageChangeAction
    | ITalkPreviewImageChangeAction;

export const talkFormState = (state: ITalk, action: TalkFormAction): ITalk => {
    switch (action.type) {
        case TITLE:
            return { ...state, title: action.payload };
        case SUBTITLE:
            return { ...state, subtitle: action.payload };
        case SPEAKERS:
            return { ...state, speaker: action.payload.split(', ').map((s) => s.trim()) };
        case DATE_TIME:
            return { ...state, talkDateTime: action.payload };
        case TAGS:
            return { ...state, tags: action.payload };
        case DESCRIPTION:
            return { ...state, description: action.payload };
        case VIDEO_URL:
            return { ...state, videoUrl: action.payload };
        case SLIDES_URL:
            return { ...state, slidesUrl: action.payload };
        case ZOOM_URL:
            return { ...state, zoomUrl: action.payload };
        case CODE_URL:
            return { ...state, sourceCodeUrl: action.payload };
        case EVENT_URL:
            return { ...state, eventUrl: action.payload };
        case MARKETING_IMAGE:
            return { ...state, marketingImage: action.payload };
        case VIDEO_THUMBNAIL_IMAGE:
            return { ...state, videoThumbnailImage: action.payload };
        case PREVIEW_IMAGE:
            return { ...state, previewImage: action.payload };
        default:
            return state;
    }
};

export const targetNameToActionType = (name: string) => {
    switch (name) {
        case TITLE:
            return TITLE;
        case SUBTITLE:
            return SUBTITLE;
        case SPEAKERS:
            return SPEAKERS;
        case DESCRIPTION:
            return DESCRIPTION;
        case VIDEO_URL:
            return VIDEO_URL;
        case SLIDES_URL:
            return SLIDES_URL;
        case ZOOM_URL:
            return ZOOM_URL;
        case CODE_URL:
            return CODE_URL;
        case EVENT_URL:
            return EVENT_URL;
        default:
            return null;
    }
};
