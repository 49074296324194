import React, { useEffect, useState } from 'react';
import { TalkCard } from '../../../components/TalkCard';
import groupBy from 'lodash/groupBy';
import qs from 'querystringify';

import { MultilineTitle, TitlePosition, TitleColor } from '../../../components/MultilineTitle';
import { Newsletter } from '../../../components/Newsletter';
import TalksImage from '../../../images/heroimages/Heroimage_AlleTT.jpg';
import { TagFilterDropdown } from '../../../components/TagFilterDropdown';
import { Heroimage } from '../../../components/Heroimage';
import { sortTagObject } from '../../../utils/tags';
import { Button, ButtonColors } from '../../../components/Button';
import { Spinner } from '../../../components/Spinner';
import { ITalk } from '../../../components/TalkInput/model';
import { NextTalk } from '../../../components/NextTalk';
import { talksApi } from '../../../utils/api/endpoints';
import { getNextTalk } from '../../../utils/api/talk';
import useScrollUp from '../../../utils/useScrollUp';

import styles from './index.module.scss';
import useQueryString from '../../../utils/useQueryString';

export interface ITalkPreview extends ITalk {
    id: string;
}

const pageSize = 20;

export const TalksOverview: React.FC = () => {
    const [limit, setLimit] = useQueryString('limit', pageSize, (s) => parseInt(s));
    const [filter, setFilter] = useQueryString<string[]>('filter', [], (s) => s.split(','));
    const [talks, setTalks] = useState<{ talks: ITalkPreview[]; count: number }>({ talks: [], count: 0 });
    const [nextTalk, setNextTalk] = useState<ITalkPreview>();
    const [tags, setTags] = useState<{ [key: string]: number }>({});
    const [loading, setLoading] = useState(false);

    useScrollUp();

    const fetchTalks = (filter: string[], from: number, limit: number) => {
        const query = qs.stringify({ tags: filter, type: 'onlyPast', from: from, limit: limit }, true);
        fetch(talksApi + query)
            .then((data) => data.json())
            .then((res) => {
                if (res.talks) {
                    const resetTalks = from === 0 && limit === pageSize;
                    setTalks((t) => ({ talks: [...(!resetTalks ? t.talks : []), ...res.talks] || [], count: res.talkCount }));
                    setLoading(false);

                    const tags = res.tags !== null && res.tags !== undefined ? sortTagObject(res.tags, 24) : {};
                    setTags(tags);
                }
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        const from = talks.talks.length;
        const talkCount = talks.count;

        let ignoreUpdate = false;
        // We should fetch more talks than available
        if (limit > talkCount && talkCount !== 0 && talkCount <= from) {
            ignoreUpdate = true;
        }
        // We are currently fetching => from is not yet updated
        if (from === limit) {
            ignoreUpdate = true;
        }

        if (!ignoreUpdate) {
            fetchTalks(filter, from, limit);
        }
    }, [filter, limit, talks]);

    useEffect(() => {
        getNextTalk()
            .then((res) => {
                const talk: ITalkPreview = res.talks !== null && res.talks !== undefined ? res.talks[0] : null;
                if (talk) {
                    talk.talkDateTime = new Date(talk.talkDateTime);
                    setNextTalk(talk);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const onLoadButtonClick = () => {
        setLoading(true);
        setLimit(limit + pageSize);
    };

    const handleFilterChange = (filter: string[]) => {
        setTalks({ talks: [], count: 0 });
        setLimit(pageSize);
        setLoading(true);
        setFilter(filter);
    };

    const groupedTalks = groupBy(talks.talks, (t) => new Date(t.talkDateTime).getFullYear());

    const talksList: React.ReactNode[] = [];
    Object.keys(groupedTalks)
        .sort((a, b) => parseInt(b) - parseInt(a))
        .map((key, index) => {
            if (index === 0) {
                talksList.push(
                    <div className={styles.gridTitleFilterWrapper} key="first_title_with_filter">
                        <MultilineTitle className={styles.gridTitle} position={TitlePosition.LEFT} color={TitleColor.LIME_GREEN} key={key}>
                            {key}
                        </MultilineTitle>
                        <TagFilterDropdown tags={tags} callback={handleFilterChange} filter={[...filter]} />
                    </div>
                );
            } else {
                talksList.push(
                    <MultilineTitle className={styles.gridTitle} position={TitlePosition.LEFT} color={TitleColor.LIME_GREEN} key={key}>
                        {key}
                    </MultilineTitle>
                );
            }

            const mappedTalks = groupedTalks[key].map((talk) => {
                return <TalkCard talk={talk} key={talk.id} />;
            });
            const talksGrid = (
                <div className={styles.grid} key={'grid' + key}>
                    {mappedTalks}
                </div>
            );
            talksList.push(talksGrid);
            return true;
        });

    return (
        <div>
            <Heroimage image={TalksImage} title="Unsere TechTalks" subTitle="in der Übersicht" />
            {nextTalk && <NextTalk talk={nextTalk} />}

            <div className={styles.talksWrapper}>{talksList}</div>
            {talks.talks.length < talks.count && (
                <div className={styles.loadMoreButton}>
                    <Button onClick={onLoadButtonClick} disabled={loading} color={ButtonColors.LIME_GREEN}>
                        {!loading ? 'MEHR TALKS ANZEIGEN' : <Spinner />}
                    </Button>
                </div>
            )}
            <Newsletter />
        </div>
    );
};
