import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Select } from './Select';
import { ReactComponent as ArrowDown } from '../../images/ExpandMore.svg';
import { ReactComponent as ArrowUp } from '../../images/ExpandLess.svg';
import classnames from 'classnames';

import styles from './index.module.scss';
import { Button, ButtonColors } from '../Button';

interface ITagFilterProps {
    tags: { [key: string]: number };
    callback: (filterArr: string[]) => void;
    filter: string[];
}

export const TagFilterDropdown: React.FC<ITagFilterProps> = ({ tags, callback, filter }) => {
    const [filterState, setFilterState] = useState<string[]>(filter);
    const [menuOpen, setMenuState] = useState(false);
    const dropdownRef = useRef<HTMLFormElement | null>(null);

    const submitFilter = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setMenuState(false);
            callback(filterState);
        },
        [callback, filterState]
    );

    const toggleMenu = useCallback(() => {
        setMenuState(!menuOpen);
    }, [menuOpen]);

    const handleClick = useCallback(
        (event: MouseEvent) => {
            if (dropdownRef.current && event.target && !dropdownRef.current.contains(event.target as Node)) {
                setMenuState(false);
                setFilterState([...filter]);
            }
        },
        [dropdownRef, setMenuState, setFilterState, filter]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => document.removeEventListener('mousedown', handleClick);
    }, [handleClick]);

    useEffect(() => {
        setFilterState(filter);
    }, [filter, setFilterState]);

    const changeFilterState = (value: string) => {
        setFilterState((s) => {
            const index = s.indexOf(value);
            if (index >= 0) {
                const copiedArray = [...s];
                copiedArray.splice(index, 1);
                return copiedArray;
            } else {
                return [...s, value];
            }
        });
    };

    return (
        <form onSubmit={submitFilter} className={styles.dropdown} ref={dropdownRef}>
            <button type="button" onClick={toggleMenu} className={styles.toggleButton}>
                <span>FILTER</span>
                <ArrowDown className={classnames({ [styles.hide]: menuOpen })} />
                <ArrowUp className={classnames({ [styles.hide]: !menuOpen })} />
            </button>
            <div className={classnames(styles.menu, { [styles.show]: menuOpen })}>
                <div className={styles.tagMap}>
                    {Object.keys(tags).map((tag) => (
                        <Select key={tag} callback={() => changeFilterState(tag)} tag={tag} selected={filterState.indexOf(tag) >= 0} />
                    ))}
                </div>
                <div style={{ padding: '1rem', paddingBottom: '0' }}>
                    <Button color={ButtonColors.GRAY} className={styles.filterButton}>
                        FILTERN
                    </Button>
                </div>
            </div>
        </form>
    );
};
