import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';

import { Newsletter } from '../../components/Newsletter';
import { TalkCard } from '../../components/TalkCard';
import { ITalkPreview } from '../Talks/Overview';
import { LoveTechTalks } from '../../components/LoveTechTalks';
import { ContentBlock } from '../../components/ContentBlock';
import { Scheme } from '../../components/ContentBlock/contentBlockUtils';
import { talksApi } from '../../utils/api/endpoints';
import { NextTalkHeroimage } from '../../components/NextTalkHeroimage';
import useScrollUp from '../../utils/useScrollUp';

export const Home: React.FC = () => {
    const [talks, setTalks] = useState<ITalkPreview[]>();
    useScrollUp();

    useEffect(() => {
        fetch(talksApi + '?type=onlyPast&limit=8')
            .then((data) => data.json())
            .then((res) => {
                setTalks(res.talks || []);
            });
    }, []);

    const latestTalks =
        talks === null || talks === undefined || talks.length === 0 ? null : (
            <ContentBlock title="Unsere aktuellen TechTalks:" scheme={Scheme.whiteGray}>
                <div className={styles.grid}>
                    {talks.map((talk) => (
                        <TalkCard key={talk.id} talk={talk} />
                    ))}
                </div>
            </ContentBlock>
        );

    return (
        <div className={styles.home}>
            <NextTalkHeroimage />
            <LoveTechTalks />
            {latestTalks}
            <Newsletter />
        </div>
    );
};
