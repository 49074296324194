import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useParams } from 'react-router';

import { TalkInput } from '../../../../components/TalkInput';
import { ITalk } from '../../../../components/TalkInput/model';
import { updateTalk, getTalk, getTags } from '../../../../utils/api/talk';

export const EditTalk: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [talk, setTalk] = useState<ITalk>();
    const [loading, setLoading] = useState<boolean>(true);
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        Auth.currentSession().catch(() => navigate('/admin/login'));
    });

    useEffect(() => {
        if (id != null) {
            getTalk(id, true)
                .then((res) => {
                    (res.talk as ITalk).talkDateTime = new Date((res.talk as ITalk).talkDateTime);
                    setTalk(res.talk);
                })
                .catch((err) => console.error(err))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        getTags()
            .then((tags) => setTags(tags))
            .catch((err) => console.error(err));
    }, []);

    if (loading) {
        return null;
    }
    if (id == null || talk === undefined) {
        return <h1>No ID found in URL!</h1>;
    } else {
        const saveTalk = (talk: ITalk) => updateTalk(talk, id);

        return <TalkInput talk={talk} saveTalk={saveTalk} tags={tags} />;
    }
};
