export const base = '/v1/';

export const talksApi = base + 'talks';

export const newsletterApi = base + 'newsletter';
export const contactApi = base + 'contact';
export const registrationApi = base + 'registration';

export const talkImageApi = base + 'images/talks';
export const insightsImageApi = base + 'images/insights';
