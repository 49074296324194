import React, { useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';
import { signIn } from '../../../utils/auth';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string>();

    const nameInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        Auth.currentSession()
            .then(() => {
                return navigate('/admin');
            })
            .catch(() => {
                return;
            });
    }, [navigate]);

    const submitLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (nameInput.current && passwordInput.current) {
            setError(undefined);
            signIn(nameInput.current.value, passwordInput.current.value)
                .then(() => {
                    return navigate('/admin');
                })
                .catch((err) => {
                    console.error(err);
                    setError(err.message);
                });
        }
    };

    return (
        <form onSubmit={submitLogin}>
            <div>Username</div>
            <input ref={nameInput} type="text" id="name" />
            <div>Password</div>
            <input ref={passwordInput} type="password" id="password" />
            <button type="submit" className={styles.adminButton}>
                LOGIN
            </button>
            {error !== undefined ? <div>{error}</div> : null}
        </form>
    );
};
