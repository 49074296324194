import React from 'react';
import { Contact } from './Contact';
import { LegalNotice } from './LegalNotice';
import useScrollUp from '../../utils/useScrollUp';

export const Imprint: React.FC = () => {
    useScrollUp();
    return (
        <div>
            <Contact />
            {/* TODO Hier findet ihr uns */}
            <LegalNotice />
        </div>
    );
};
