import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router';
import { AdminTalks } from './Talks';
import { Login } from './Login';
import { NewTalk } from './Talks/New';
import { EditTalk } from './Talks/Edit';

import styles from './AdminApp.module.scss';
import { signOut } from '../../utils/auth';
import { Insights } from './Insights';
import { Auth } from 'aws-amplify';
import { initAuth } from '../../utils/auth_config';

export const AdminApp: React.FC<{ '*'?: string }> = (props) => {
    const [isInitialized, setInitialized] = useState(false);

    useEffect(() => {
        initAuth().then(() => setInitialized(true));
    }, []);

    if (isInitialized) {
        return <AdminAppInternal {...props} />;
    }
    return null;
};

const AdminAppInternal: React.FC<{ '*'?: string }> = (props) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const path = props['*'];

    useEffect(() => {
        setIsLoading(true);
        Auth.currentSession()
            .then(() => {
                setAuth(true);
            })
            .catch(() => navigate('/admin/login'))
            .finally(() => setIsLoading(false));
    }, [navigate, path]);

    let nav: any;
    if (!isLoading && auth) {
        nav = (
            <div className={styles.adminContainer}>
                <div className={styles.adminNav}>
                    <Link to="/admin/talks" className={styles.link}>
                        Talks
                    </Link>
                    <Link to="/admin/insights" className={styles.link}>
                        Einblicke
                    </Link>
                    <div className={styles.spacer} />
                    <div
                        className={styles.link}
                        onClick={() =>
                            signOut()
                                .then(() => setAuth(false))
                                .then(() => navigate('/admin/login'))
                        }
                    >
                        Logout
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {nav}
            <div className={styles.container}>
                <Routes>
                    <Route path="talks" element={<AdminTalks />} />
                    <Route path="insights" element={<Insights />} />
                    <Route path="talks/new" element={<NewTalk />} />
                    <Route path="talks/edit/:id" element={<EditTalk />} />
                    <Route path="login" element={<Login />} />
                </Routes>
            </div>
        </div>
    );
};
