import React, { useCallback, useEffect, useState, MouseEvent } from 'react';

import styles from './index.module.scss';

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';
import { talksApi } from '../../../utils/api/endpoints';
import { ITalk } from '../../../components/TalkInput/model';
import { Link } from 'react-router-dom';

export const AdminTalks: React.FC = () => {
    const navigate = useNavigate();
    const [talks, setTalks] = useState<ITalk[] | null>(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        Auth.currentSession().catch(() => {
            navigate('/admin/login');
        });
    }, [navigate]);

    const decreasePage = () => {
        setPage(page - 1);
    };

    const increasePage = () => {
        setPage(page + 1);
    };

    const fetchTalks = useCallback(() => {
        const fromString = 'from=' + (page * 20 - 20).toString();
        const limitString = 'limit=' + (page * 20 + 1).toString();
        const datetimeString = 'datetime=' + Date.now();
        fetch(talksApi + '?' + fromString + '&' + limitString + '&' + datetimeString)
            .then((data) => data.json())
            .then((res) => setTalks(res.talks || []));
    }, [page]);

    useEffect(() => {
        fetchTalks();
    }, [fetchTalks]);

    const deleteTalk = (talkId: string) => {
        if (window.confirm('Diesen Talk wirklich löschen?')) {
            Auth.currentSession()
                .then((session) => {
                    const params = {
                        headers: {
                            Authorization: session.getIdToken().getJwtToken(),
                        },
                        method: 'DELETE',
                    };

                    fetch(talksApi + '/' + talkId, params)
                        .then(() => fetchTalks())
                        .catch((err) => console.error(err));
                })
                .catch(() => {
                    navigate('/admin/login');
                    console.error('No user found.');
                });
        }
    };

    const talkRows =
        talks == null ? (
            <tr>
                <td colSpan={3}>Loading</td>
            </tr>
        ) : (
            talks.map((talk: any) => <TalkRow key={talk.id} talk={talk} deleteFunc={deleteTalk} />)
        );

    return (
        <>
            <table className={styles.talksTable}>
                <thead>
                    <tr>
                        <td>Title</td>
                        <td>Speaker</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>{talkRows}</tbody>
                <tfoot>
                    <tr>
                        <td />
                        <td />
                        <td>
                            <Link to="/admin/talks/new" className={styles.addTalk}>
                                Add Talk
                            </Link>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div className={styles.pagination}>
                <button disabled={page === 1} onClick={decreasePage}>
                    Prev
                </button>
                <label>{page}</label>
                <button disabled={talks != null && talks.length <= 20} onClick={increasePage}>
                    Next
                </button>
            </div>
        </>
    );
};

const TalkRow: React.FC<{ talk: any; deleteFunc: (id: string) => void }> = ({ talk, deleteFunc }) => {
    const navigate = useNavigate();

    const deleteTalk = (event: MouseEvent) => {
        event.stopPropagation();
        deleteFunc(talk.id);
    };

    return (
        <tr key={'talk' + talk.id} onClick={() => navigate('/admin/talks/edit/' + talk.id)}>
            <td>
                <Link to={'/admin/talks/edit/' + talk.id} className={styles.tableRowLink}>
                    {talk.title}
                </Link>
            </td>
            <td>{talk.speaker.join(', ')}</td>
            <td>
                <button onClick={deleteTalk} className={styles.rowDeleteButton}>
                    Delete
                </button>
            </td>
        </tr>
    );
};
