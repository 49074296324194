import React from 'react';
import { MultilineTitle, TitleColor, TitlePosition } from '../../../components/MultilineTitle';

import styles from './index.module.scss';
import { PrivacyLink } from '../../../components/PrivacyLink';

export const LegalNotice: React.FC = () => {
    return (
        <>
            <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.sectionTitle}>
                Haftungsausschluss
            </MultilineTitle>
            <div className={styles.textContainer}>
                <p>
                    Haftung für Inhalte
                    <br />
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verant-wortlich. Nach §§
                    8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Infor-mationen zu überwachen oder
                    nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine dies-bezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                    entfernen.
                </p>
                <p>
                    Haftung für Links
                    <br />
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
                    Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                    verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                    zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechts-verletzungen werden wir derartige Links umgehend
                    entfernen.
                </p>
                <p>
                    Urheberrecht
                    <br />
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Ver-vielfältigung,
                    Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                    jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urhe-berrechte Dritter beachtet. Insbesondere werden
                    Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheber-rechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>
            </div>
            <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.sectionTitle}>
                Impressum
            </MultilineTitle>
            <div className={styles.textContainer}>
                <p>
                    IMPRESSUM
                    <br />
                    Handelsregisternummer: HRB 87728, Amtsgericht Darmstadt
                    <br />
                    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE264462313 Geschäftsführer: Patrick Wolf
                    <br />
                    Verantwortlich für den Inhalt nach § 55 Abs. 2
                </p>
            </div>
            <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.sectionTitle}>
                Datenschutzerklärung
            </MultilineTitle>
            <div className={styles.textContainer}>
                <p>
                    DATENSCHUTZERKLÄRUNG
                    <br />
                    <PrivacyLink>Datenschutzerklärung als PDF herunterladen</PrivacyLink>
                </p>
            </div>
        </>
    );
};
