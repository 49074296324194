const addTrailingZeros = (dateProperty: string) => {
    if (dateProperty.length < 2) {
        return '0' + dateProperty;
    }
    return dateProperty;
};

export const dateToTimeString = (date: Date): string => addTrailingZeros(date.getHours().toString()) + ':' + addTrailingZeros(date.getUTCMinutes().toString());

export const dateToDateString = (date: Date): string => date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();

export const timeAndDateStringToDate = (time: string, date: string): Date => {
    const dateParts = date.split('.').map((s) => parseInt(s));
    const timeParts = time.split(':').map((s) => parseInt(s));
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1]);
};
