import React, { useState, useEffect } from 'react';
import { LoveTechTalks } from '../../components/LoveTechTalks';
import { Heroimage } from '../../components/Heroimage';
import AboutImage from '../../images/heroimages/Heroimage_WassinddieTechTalks.jpg';

import styles from './index.module.scss';
import { Newsletter } from '../../components/Newsletter';
import { LinkAsButton } from '../../components/LinkAsButton';
import { ButtonColors } from '../../components/Button';
import { ContentBlock } from '../../components/ContentBlock';
import { Scheme } from '../../components/ContentBlock/contentBlockUtils';
import { InsightsGrid } from '../../components/InsightsGrid';
import { getInsights } from '../../utils/api/image';
import useScrollUp from '../../utils/useScrollUp';

export const AboutUs: React.FC = () => {
    const [insights, setInsights] = useState<{ Key: string }[]>([]);
    useScrollUp();

    useEffect(() => {
        getInsights()
            .then((data) => setInsights(data))
            .catch((err) => console.error(err));
    }, []);

    return (
        <div>
            <Heroimage image={AboutImage} title="Was sind die" subTitle="TechTalks?" />
            <div className={styles.bulletPointWrapper}>
                <div className={styles.bulletPointList}>
                    <ul>
                        <li>kostenlose Praxisvorträge von Entwicklern für Entwickler und Studenten</li>
                        <li>spannende Themen aus unserem Arbeitsalltag und brandneue Technologien</li>
                        <li>Diskussion und Austausch bei Pizza und kühlen Getränken</li>
                    </ul>
                    <ul>
                        <li>jeden letzten Donnerstag im Monat</li>
                        <li>Lightning Talks sind kurze Impulsvorträge zu unterschiedlichen Themen, die immer im November stattfinden.</li>
                    </ul>
                </div>
                <LinkAsButton color={ButtonColors.GRAY} to="/registration" className={styles.centeredLinkButton}>
                    KOSTENLOS ANMELDEN
                </LinkAsButton>
            </div>
            <LoveTechTalks />
            <div className={styles.interested}>
                <h2>Interesse geweckt? Sieh dir vergangene TechTalks an!</h2>
                <LinkAsButton color={ButtonColors.GRAY} to="/talks" className={styles.centeredLinkButton}>
                    ZU DEN TECHTALKS
                </LinkAsButton>
            </div>
            <ContentBlock title="Unser TechTalk-Team" scheme={Scheme.grayWhite}>
                <>
                    <p>Die Organisation der TechTalks liegt in der Hand unseres bunt gemischten TechTalk-Teams.</p>
                    <p>Hast du Feedback, das du gerne mit uns teilen möchtest, oder Anregungen für spannende Themen?</p>
                    <p>Wir freuen uns, von dir zu hören!</p>
                </>
            </ContentBlock>
            {insights.length > 0 && (
                <ContentBlock title="Einblicke" scheme={Scheme.whiteGreen}>
                    <InsightsGrid insights={insights} />
                </ContentBlock>
            )}
            <Newsletter />
        </div>
    );
};
