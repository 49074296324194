import React from 'react';

import classnames from 'classnames';
import styles from './index.module.scss';

interface ITagProps {
    children: React.ReactNode;
    className?: string;
}

export const Tag: React.FC<ITagProps> = ({ children, className }) => {
    return <span className={classnames(className, styles.tag)}>&lt;{children}/&gt;</span>;
};
