import { ITalk } from '../../components/TalkInput/model';
import { Auth } from 'aws-amplify';
import { talksApi } from './endpoints';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const handleFetchErrors = async (response: Response) => {
    if (!response.ok) {
        throw Error((await response.json()).message);
    }
    return response;
};

export const updateTalk = async (talk: ITalk, id: string): Promise<void> => {
    let session: CognitoUserSession;
    try {
        session = await Auth.currentSession();
    } catch (e) {
        console.error('No user found.');
        return;
    }

    const params: RequestInit = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
        },
        body: JSON.stringify(talk),
        method: 'PUT',
    };

    const response = await fetch(talksApi + '/' + id, params);
    await handleFetchErrors(response);
};

export const addTalk = async (talk: ITalk): Promise<void> => {
    let session: CognitoUserSession;
    try {
        session = await Auth.currentSession();
    } catch (e) {
        console.error('No user found.');
        return;
    }

    const params: RequestInit = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
        },
        body: JSON.stringify(talk),
        method: 'POST',
    };

    const response = await fetch(talksApi, params);
    await handleFetchErrors(response);
};

export const getNextTalk = () => {
    return fetch(talksApi + '?type=nextOnly')
        .then(handleFetchErrors)
        .then((data) => data.json());
};

export const getTalk = (id: string, ignoreCache: boolean = false) => {
    let url = talksApi + '/' + id;
    if (ignoreCache) {
        url += '?datetime=' + Date.now();
    }
    return fetch(url)
        .then(handleFetchErrors)
        .then((data) => data.json());
};

export const getTags = () => {
    let url = talksApi + '?from=0&limit=1';
    return fetch(url)
        .then(handleFetchErrors)
        .then((data) => data.json())
        .then((res) => Object.keys(res.tags));
};
