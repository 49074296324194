import React from 'react';

import styles from './index.module.scss';

export const Spinner: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{ width: '1.5rem', height: '1.5rem' }}>
            <rect x="0" y="0" width="100" height="100" fill="none" />
            <circle className={styles.spinner} cx="50" cy="50" r="40" stroke="#999999" fill="none" strokeWidth="6" strokeLinecap="round" />
        </svg>
    );
};
