import React from 'react';
import { Spinner } from '../Spinner';

import styles from './index.module.scss';

export const PageLoading: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <Spinner></Spinner>
        </div>
    );
};
