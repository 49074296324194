import React, { useEffect, useState, useRef, useCallback } from 'react';
import classnames from 'classnames';
import throttle from 'lodash/throttle';

import styles from './index.module.scss';

export enum TitlePosition {
    LEFT,
    RIGHT,
}

export enum TitleColor {
    GRAY,
    WHITE,
    LIME_GREEN,
    GREEN,
}

const reduceColor = (color: TitleColor) => {
    switch (color) {
        case TitleColor.GRAY:
            return styles.gray;
        case TitleColor.WHITE:
            return styles.white;
        case TitleColor.LIME_GREEN:
            return styles.lime_green;
        case TitleColor.GREEN:
            return styles.green;
        default:
            return styles.white;
    }
};

const reducePosition = (position: TitlePosition) => {
    switch (position) {
        case TitlePosition.RIGHT:
            return styles.right;
        case TitlePosition.LEFT:
            return styles.left;
        default:
            return styles.left;
    }
};

interface IMultilineTitleProps {
    children: string;
    color: TitleColor;
    position: TitlePosition;
    className?: string;
}

export const MultilineTitle: React.FC<IMultilineTitleProps> = ({ children, color, position, className }) => {
    const [text, setText] = useState<Array<Array<String>>>([[]]);
    const hiddenContainerRef = useRef<HTMLDivElement>(null);

    const throttled = useRef(
        throttle((ch) => {
            const containerWidth = getContainerInnerWidth();
            if (hiddenContainerRef && hiddenContainerRef.current) {
                let text: Array<Array<String>> = [[]];
                let currentWidth = 0;
                let row = 0;
                let textParts = ch.split(' ');
                for (let i = 0; i < hiddenContainerRef.current.children.length; i++) {
                    const child = hiddenContainerRef.current.children[i] as HTMLSpanElement;
                    currentWidth += child.offsetWidth;
                    if (currentWidth > containerWidth) {
                        row++;
                        currentWidth = child.offsetWidth;
                        text[row] = [];
                    }
                    text[row].push(textParts[i]);
                }

                // If the last row contains only one word, we pull the last word of the previous row into the last row
                if (text.length > 1 && text[row - 1].length > 2 && text[row].length === 1) {
                    text[row] = [...text[row - 1].splice(-1), ...text[row]];
                }
                setText(text);
            }
        }, 500)
    );

    const computeRows = useCallback(() => throttled.current(children), [children]);

    const getContainerInnerWidth = () => {
        if (hiddenContainerRef && hiddenContainerRef.current) {
            const computedStyle = getComputedStyle(hiddenContainerRef.current);
            if (computedStyle.paddingLeft && computedStyle.paddingRight) {
                let elementWidth = hiddenContainerRef.current.clientWidth;

                elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

                return elementWidth;
            }
        }
        return 0;
    };

    useEffect(() => {
        computeRows();
        window.addEventListener('resize', computeRows);

        return () => {
            window.removeEventListener('resize', computeRows);
        };
    }, [computeRows]);

    const rowClassNames = classnames(reduceColor(color), reducePosition(position), styles.title, styles.visibleTitle);

    const rows = text.map((row, i) => (
        <React.Fragment key={'fragment' + i}>
            <div
                style={{
                    marginTop: i > 0 ? '0.5rem' : undefined,
                    textAlign: position === TitlePosition.RIGHT ? 'right' : 'left',
                }}
            >
                <span className={rowClassNames}>{row.join(' ')}</span>
            </div>
        </React.Fragment>
    ));

    const hiddenContainerClassNames = classnames(reducePosition(position), styles.hiddenContainer, styles.title);

    return (
        <div className={classnames(className, styles.text)} style={{ textAlign: position === TitlePosition.RIGHT ? 'right' : 'left' }}>
            <div ref={hiddenContainerRef} className={hiddenContainerClassNames}>
                {children.split(' ').map((s, i) => (
                    <span key={'text' + i}>
                        {i > 0 ? <span>&nbsp;</span> : null}
                        {s}
                    </span>
                ))}
            </div>
            {rows}
        </div>
    );
};
