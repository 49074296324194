import React from 'react';
import { ReactComponent as Twitter } from '../../images/Twitter.svg';
import { ReactComponent as Facebook } from '../../images/Facebook.svg';
import { ReactComponent as Instagram } from '../../images/Instagram.svg';
import { ReactComponent as Xing } from '../../images/Xing.svg';
import { ReactComponent as Meetup } from '../../images/Meetup.svg';

import styles from './index.module.scss';

export const Social: React.FC = () => (
    <div className={styles.social}>
        <a href="https://www.facebook.com/cosee.gmbh/" target="_blank" rel="noopener noreferrer nofollow">
            <Facebook />
        </a>
        <a href="https://twitter.com/coseeaner" target="_blank" rel="noopener noreferrer nofollow">
            <Twitter />
        </a>
        <a href="https://www.xing.com/companies/coseegmbh" target="_blank" rel="noopener noreferrer nofollow">
            <Xing />
        </a>
        <a href="https://www.instagram.com/coseeaner/" target="_blank" rel="noopener noreferrer nofollow">
            <Instagram />
        </a>
        <a href="http://www.meetup.com/de-DE/cosee-TechTalks/" target="_blank" rel="noopener noreferrer nofollow">
            <Meetup />
        </a>
    </div>
);
