import React from 'react';

import styles from './index.module.scss';
import classnames from 'classnames';

export enum ButtonColors {
    WHITE,
    GRAY,
    GREEN,
    LIME_GREEN,
    TRANSPARENT,
}

export const reduceColor = (color: ButtonColors) => {
    switch (color) {
        case ButtonColors.GRAY:
            return styles.gray;
        case ButtonColors.WHITE:
            return styles.white;
        case ButtonColors.GREEN:
            return styles.green;
        case ButtonColors.LIME_GREEN:
            return styles.limeGreen;
        case ButtonColors.TRANSPARENT:
            return styles.transparent;
        default:
            return styles.white;
    }
};

interface IButtonProps {
    children: React.ReactNode;
    color: ButtonColors;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
}

export const Button: React.FC<IButtonProps> = ({ children, color, className, onClick, disabled }) => {
    const buttonClassNames = classnames(styles.button, reduceColor(color), className);

    return (
        <button className={buttonClassNames} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};
