import React, { useReducer, useCallback, useState, useEffect, useRef, ChangeEventHandler, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';

import {
    TITLE,
    SUBTITLE,
    SPEAKERS,
    TAGS,
    DESCRIPTION,
    VIDEO_URL,
    SLIDES_URL,
    CODE_URL,
    talkFormState,
    emptyTalk,
    ITalk,
    DATE_TIME,
    targetNameToActionType,
    EVENT_URL,
    MARKETING_IMAGE,
    PREVIEW_IMAGE,
    VIDEO_THUMBNAIL_IMAGE,
    ZOOM_URL,
} from './model';
import { timeAndDateStringToDate, dateToTimeString, dateToDateString } from '../../utils/datetime';
import { uploadTalkImage } from '../../utils/api/image';

import styles from './index.module.scss';

interface ITalkInputProps {
    saveTalk: (talk: ITalk) => Promise<any>;
    talk?: ITalk;
    tags: string[];
}

export const TalkInput: React.FC<ITalkInputProps> = ({ talk, saveTalk, tags: existingTags }) => {
    const navigate = useNavigate();
    const initialTalk: ITalk = talk || cloneDeep(emptyTalk);
    const [state, dispatch] = useReducer(talkFormState, initialTalk);
    const [time, setTime] = useState(dateToTimeString(initialTalk.talkDateTime) || '01.01.2020');
    const [date, setDate] = useState(dateToDateString(initialTalk.talkDateTime) || '19:00');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [tags, setTags] = useState<string[]>([]);
    const previewImageRef = useRef<HTMLInputElement | null>(null);
    const [previewImagePreview, setPreviewImagePreview] = useState<string>();
    const marketingImageRef = useRef<HTMLInputElement | null>(null);
    const [marketingImagePreview, setMarketingImagePreview] = useState<string>();
    const videoThumbnailImageRef = useRef<HTMLInputElement | null>(null);
    const [videoThumbnailImagePreview, setVideoThumbnailImagePreview] = useState<string>();
    const tagInputRef = useRef<HTMLInputElement | null>(null);

    const changeTag = (tag: string) => {
        let newTags: string[];
        if (!state.tags) {
            newTags = [tag];
        } else {
            newTags = [...state.tags];
            if (state.tags.indexOf(tag) >= 0) {
                newTags.splice(newTags.indexOf(tag), 1);
                if (existingTags.indexOf(tag) < 0) {
                    setTags((prevTags) => {
                        const newTags = [...prevTags];
                        newTags.splice(newTags.indexOf(tag), 1);
                        return newTags;
                    });
                }
            } else {
                newTags.push(tag);
            }
        }

        dispatch({
            type: TAGS,
            payload: newTags,
        });
    };

    const addNewTag = () => {
        const tagInput = tagInputRef.current;
        if (tagInput !== null && tagInput.value) {
            const tag = tagInput.value;
            setTags((tags) => [...tags, tag]);
            changeTag(tag);
            tagInput.value = '';
        }
    };

    const changeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.currentTarget) {
            setTime(event.currentTarget.value);
        }
    };

    const changeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.currentTarget) {
            setDate(event.currentTarget.value);
        }
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event && event.currentTarget) {
            const type = targetNameToActionType(event.currentTarget.name);
            if (type !== null) {
                dispatch({
                    type,
                    payload: event.currentTarget.value,
                });
            }
        }
    };

    const deletePreviewImageHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (state.previewImage) {
            dispatch({
                type: PREVIEW_IMAGE,
                payload: undefined,
            });
        } else {
            if (!isNil(previewImageRef.current)) {
                previewImageRef.current.value = '';
            }
            setPreviewImagePreview(undefined);
        }
    };

    const onChangePreviewImage: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setPreviewImagePreview(undefined);
            return;
        }
        setPreviewImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    const deleteMarketingImageHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (state.marketingImage) {
            dispatch({
                type: MARKETING_IMAGE,
                payload: undefined,
            });
        } else {
            if (!isNil(marketingImageRef.current)) {
                marketingImageRef.current.value = '';
            }
            setMarketingImagePreview(undefined);
        }
    };

    const onChangeMarketingImage: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setMarketingImagePreview(undefined);
            return;
        }
        setMarketingImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    const deleteVideoThumbnailImageHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (state.videoThumbnailImage) {
            dispatch({
                type: VIDEO_THUMBNAIL_IMAGE,
                payload: undefined,
            });
        } else {
            if (!isNil(videoThumbnailImageRef.current)) {
                videoThumbnailImageRef.current.value = '';
            }
            setVideoThumbnailImagePreview(undefined);
        }
    };

    const onChangeVideoThumbnailImage: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setVideoThumbnailImagePreview(undefined);
            return;
        }
        setVideoThumbnailImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    useEffect(() => {
        const dateTime = timeAndDateStringToDate(time, date);
        dispatch({ type: DATE_TIME, payload: dateTime });
    }, [time, date]);

    useEffect(() => {
        setTags(existingTags);
    }, [existingTags]);

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            setLoading(true);
            event.preventDefault();
            const talk = state;
            try {
                if (!isNil(previewImageRef.current) && previewImageRef.current.files && previewImageRef.current.files[0]) {
                    const previewImage = await uploadTalkImage(previewImageRef.current.files[0]);
                    talk.previewImage = previewImage ? previewImage : state.previewImage;
                }

                if (!isNil(marketingImageRef.current) && marketingImageRef.current.files && marketingImageRef.current.files[0]) {
                    const marketingImage = await uploadTalkImage(marketingImageRef.current.files[0]);
                    talk.marketingImage = marketingImage ? marketingImage : state.marketingImage;
                }

                if (!isNil(videoThumbnailImageRef.current) && videoThumbnailImageRef.current.files && videoThumbnailImageRef.current.files[0]) {
                    const videoThumbnailImage = await uploadTalkImage(videoThumbnailImageRef.current.files[0]);
                    talk.videoThumbnailImage = videoThumbnailImage ? videoThumbnailImage : state.videoThumbnailImage;
                }
            } catch (err) {
                console.error(err);
            }

            try {
                await saveTalk(talk);
                await navigate('/admin/talks');
            } catch (e) {
                if (e instanceof Error) {
                    setError(e.message);
                }
            }
            setLoading(false);
        },
        [navigate, state, saveTalk]
    );
    return (
        <form onSubmit={handleSubmit}>
            <table className={styles.inputTable}>
                <colgroup>
                    <col width="170px" />
                    <col />
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            <label>Title</label>
                        </td>
                        <td>
                            <input className={styles.input} name={TITLE} type="text" onChange={changeHandler} value={state.title} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Subtitle</label>
                        </td>
                        <td>
                            <input className={styles.input} name={SUBTITLE} type="text" onChange={changeHandler} value={state.subtitle} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Speakers</label>
                        </td>
                        <td>
                            <input className={styles.input} name={SPEAKERS} type="text" onChange={changeHandler} value={state.speaker.join(', ')} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Date</label>
                        </td>
                        <td>
                            <input
                                className={styles.input}
                                name="DATE"
                                type="text"
                                pattern="[\d]{1,2}\.[\d]{1,2}\.[\d]{4}"
                                onChange={changeDate}
                                value={date}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Time</label>
                        </td>
                        <td>
                            <input className={styles.input} name="TIME" type="text" pattern="[\d]{2}:[\d]{2}" onChange={changeTime} value={time} />
                        </td>
                    </tr>
                    <tr>
                        <td>Available Tags</td>
                        <td>
                            <div className={styles.tagContainer}>
                                {tags
                                    .sort((a, b) => (a.toLowerCase() === b.toLowerCase() ? 0 : a.toLowerCase() > b.toLowerCase() ? 1 : -1))
                                    .map((t, i) => {
                                        let className = styles.tag;
                                        if ((state.tags || []).indexOf(t) >= 0) {
                                            className += ' ' + styles.activeTag;
                                        }
                                        return (
                                            <span className={className} key={'tag_' + i} onClick={() => changeTag(t)} style={{}}>
                                                {t}
                                            </span>
                                        );
                                    })}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Add new tag</td>
                        <td>
                            <input ref={tagInputRef} style={{ width: '200px' }} />
                            <button onClick={addNewTag} type="button">
                                Add
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Description</label>
                        </td>
                        <td>
                            <textarea className={styles.textarea} name={DESCRIPTION} onChange={changeHandler} value={state.description} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Video Url</label>
                        </td>
                        <td>
                            <input className={styles.input} name={VIDEO_URL} type="text" onChange={changeHandler} value={state.videoUrl} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Slides Url</label>
                        </td>
                        <td>
                            <input className={styles.input} name={SLIDES_URL} type="text" onChange={changeHandler} value={state.slidesUrl} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Source Code Url</label>
                        </td>
                        <td>
                            <input className={styles.input} name={CODE_URL} type="text" onChange={changeHandler} value={state.sourceCodeUrl} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Event Url(Eventbrite)</label>
                        </td>
                        <td>
                            <input className={styles.input} name={EVENT_URL} type="text" onChange={changeHandler} value={state.eventUrl} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Zoom Url</label>
                        </td>
                        <td>
                            <input className={styles.input} name={ZOOM_URL} type="text" onChange={changeHandler} value={state.zoomUrl} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Preview Image</label>
                        </td>
                        <td>
                            <input
                                name={'talk_image'}
                                ref={previewImageRef}
                                type="file"
                                accept="image/png, image/jpeg"
                                style={{
                                    display: state.previewImage || previewImagePreview ? 'none' : undefined,
                                }}
                                onChange={onChangePreviewImage}
                            />
                            {state.previewImage || previewImagePreview ? (
                                <div className={styles.imageInput}>
                                    <img
                                        style={{ maxWidth: '600px' }}
                                        src={state.previewImage ? '/' + state.previewImage : previewImagePreview}
                                        alt="speaker of the talk"
                                    />
                                    <button onClick={deletePreviewImageHandler} className={styles.deleteButton} type="button">
                                        Delete
                                    </button>
                                </div>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Marketing Image</label>
                        </td>
                        <td>
                            <input
                                name={'talk_image'}
                                ref={marketingImageRef}
                                type="file"
                                accept="image/png, image/jpeg"
                                style={{
                                    display: state.marketingImage || marketingImagePreview ? 'none' : undefined,
                                }}
                                onChange={onChangeMarketingImage}
                            />
                            {state.marketingImage || marketingImagePreview ? (
                                <div className={styles.imageInput}>
                                    <img
                                        style={{ maxWidth: '600px' }}
                                        src={state.marketingImage ? '/' + state.marketingImage : marketingImagePreview}
                                        alt="speaker of the talk"
                                    />
                                    <button onClick={deleteMarketingImageHandler} className={styles.deleteButton} type="button">
                                        Delete
                                    </button>
                                </div>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Video Thumbnail Image</label>
                        </td>
                        <td>
                            <input
                                name={'talk_image'}
                                ref={videoThumbnailImageRef}
                                type="file"
                                accept="image/png, image/jpeg"
                                style={{
                                    display: state.videoThumbnailImage || videoThumbnailImagePreview ? 'none' : undefined,
                                }}
                                onChange={onChangeVideoThumbnailImage}
                            />
                            {state.videoThumbnailImage || videoThumbnailImagePreview ? (
                                <div className={styles.imageInput}>
                                    <img
                                        style={{ maxWidth: '600px' }}
                                        src={state.videoThumbnailImage ? '/' + state.videoThumbnailImage : videoThumbnailImagePreview}
                                        alt="speaker of the talk"
                                    />
                                    <button onClick={deleteVideoThumbnailImageHandler} className={styles.deleteButton} type="button">
                                        Delete
                                    </button>
                                </div>
                            ) : null}
                        </td>
                    </tr>
                </tbody>
            </table>
            {error ? (
                <>
                    <br />
                    <b style={{ color: 'red' }}>{error}</b>
                    <br />
                </>
            ) : null}
            <br />
            <button type="submit" disabled={loading} className={styles.saveButton}>
                Save talk
            </button>
        </form>
    );
};
