import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './App.module.scss';

import { Header } from './components/Header';
import { Home } from './pages/Home';
import { Imprint } from './pages/Imprint';
import { TalksOverview } from './pages/Talks/Overview';
import { Registration } from './pages/Registration';
import { Footer } from './components/Footer';
import { TalkDetail } from './pages/Talks/Detail';
import { AboutUs } from './pages/AboutUs';
import throttle from 'lodash/throttle';

import './styles/normalize.css';
import './styles/styles.css';
import './styles/fonts.css';
import './utils/auth_config';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router';

const App: React.FC = () => {
    const [width, setWidth] = useState(1000);

    const throttled = useRef(
        throttle((w) => {
            if ((w < 500 && window.outerWidth < 500) || (w >= 500 && window.outerWidth >= 500)) {
                return;
            }

            const viewport = document.getElementById('vp');
            if (viewport && viewport.parentNode) {
                viewport.parentNode.removeChild(viewport);
            }

            const newViewport = document.createElement('meta');
            newViewport.setAttribute('name', 'viewport');
            newViewport.setAttribute('id', 'vp');
            if (window.outerWidth <= 500) {
                newViewport.setAttribute('content', 'user-scalable=no,width=500');
            } else {
                newViewport.setAttribute('content', 'width=device-width, initial-scale=1');
            }
            document.head.appendChild(newViewport);
            setWidth(window.outerWidth);
        }, 500)
    );

    const handleResize = useCallback(() => throttled.current(width), [width]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        setWidth(window.outerWidth);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return (
        <>
            <Header />
            <div className={styles.contentWrapper}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="about" element={<AboutUs />} />
                    <Route path="imprint" element={<Imprint />} />
                    <Route path="talks" element={<TalksOverview />} />
                    <Route path="talk/:id" element={<TalkDetail />} />
                    <Route path="registration" element={<Registration />} />
                </Routes>
            </div>
            <Footer />
        </>
    );
};

export default App;
