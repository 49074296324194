import React from 'react';

import styles from './index.module.scss';

interface IInsightsProps {
    insights: { Key: string }[];
    viewComponent?: React.FC<{ src: string }>;
}

export const SimpleView: React.FC<{ src: string }> = ({ src }) => {
    return <img src={`/${src}`} alt="the TechTalks" />;
};

export const InsightsGrid: React.FC<IInsightsProps> = ({ insights, viewComponent = SimpleView }) => {
    return (
        <div className={styles.insightsGrid}>
            {insights.length > 0 &&
                insights.map((item) => (
                    <div className={styles.insightsGridItem} key={item.Key}>
                        {React.createElement(viewComponent, { src: item.Key })}
                    </div>
                ))}
        </div>
    );
};
