import { Auth } from 'aws-amplify';
import { insightsImageApi, talkImageApi } from './endpoints';

export const uploadTalkImage = async (image: File): Promise<string> => {
    try {
        const session = await Auth.currentSession();

        const body = {
            filename: image.name,
            contentType: image.type,
        };

        let params: RequestInit = {
            headers: {
                Authorization: session.getIdToken().getJwtToken(),
            },
            method: 'POST',
            body: JSON.stringify(body),
        };

        const response = await fetch(talkImageApi, params);
        const presignedUrlResponse = (await response.json()) as { url: string; key: string };

        params = {
            body: image,
            method: 'PUT',
            headers: {
                'Content-Type': image.type,
            },
        };

        await fetch(presignedUrlResponse.url, params);

        return presignedUrlResponse.key;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const uploadInsightImage = (image: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        Auth.currentSession()
            .then((session) => {
                const body = {
                    filename: image.name,
                    contentType: image.type,
                };

                const params: RequestInit = {
                    headers: {
                        Authorization: session.getIdToken().getJwtToken(),
                    },
                    method: 'POST',
                    body: JSON.stringify(body),
                };

                return fetch(insightsImageApi, params);
            })
            .then((res) => res.json())
            .then((res) => {
                const params: RequestInit = {
                    body: image,
                    method: 'PUT',
                    headers: {
                        'Content-Type': image.type,
                    },
                };
                return fetch(res.url, params).then(() => resolve(res.key));
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const deleteInsight = (key: string): Promise<any> => {
    return Auth.currentSession().then((session) => {
        const params = {
            headers: {
                Authorization: session.getIdToken().getJwtToken(),
            },
            method: 'DELETE',
        };
        return fetch(insightsImageApi + '/' + key.substr(key.lastIndexOf('/') + 1), params);
    });
};

export const getInsights = (ignoreCache: boolean = false): Promise<{ Key: string }[]> => {
    let url = insightsImageApi;
    if (ignoreCache) {
        url += '?datetime=' + Date.now();
    }
    return fetch(url).then((res) => res.json());
};
