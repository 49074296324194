export const sortTagObject = (tags: { [key: string]: number }, count: number) => {
    const sortableTags: [string, number][] = [];
    Object.keys(tags).forEach((tag) => {
        sortableTags.push([tag, tags[tag]]);
    });

    sortableTags.sort((a, b) => {
        return b[1] - a[1];
    });

    const objSorted: { [key: string]: number } = {};
    sortableTags.slice(0, count).forEach((tag) => {
        objSorted[tag[0]] = tag[1];
    });

    return objSorted;
};
