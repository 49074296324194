import React, { useState, useEffect } from 'react';
import { ITalkPreview } from '../../pages/Talks/Overview';
import { getNextTalk } from '../../utils/api/talk';
import { MultilineTitle, TitlePosition, TitleColor } from '../MultilineTitle';
import { dateToDateString } from '../../utils/datetime';

import styles from './index.module.scss';
import { LinkAsButton } from '../LinkAsButton';
import { ButtonColors } from '../Button';
import TalksImage from '../../images/heroimages/Heroimage_NextTalkFallback.jpg';

export const NextTalkHeroimage: React.FC = () => {
    const [talk, setTalk] = useState<ITalkPreview | undefined | null>(undefined);

    useEffect(() => {
        getNextTalk()
            .then((res) => {
                if (res.talks.length !== 0) {
                    const talkDateTime = new Date(res.talks[0].talkDateTime);
                    setTalk({ ...res.talks[0], talkDateTime });
                } else {
                    setTalk(null);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    if (talk === undefined) {
        return <></>;
    } else if (talk === null) {
        return (
            <div className={styles.container}>
                <img src={TalksImage} className={styles.image} alt="" />
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                {talk.marketingImage !== undefined ? (
                    <img
                        src={talk.marketingImage}
                        alt={''}
                        className={styles.image}
                        style={{ backgroundImage: 'url(' + TalksImage + ')', backgroundPosition: 'center center', backgroundSize: '100%' }}
                    />
                ) : (
                    <img src={TalksImage} className={styles.image} alt="" />
                )}
                <div className={styles.absolut}>
                    <MultilineTitle position={TitlePosition.RIGHT} color={TitleColor.LIME_GREEN} className={styles.dateTitle}>
                        {`[${dateToDateString(talk.talkDateTime)} | ${talk.talkDateTime.getHours()} Uhr]`}
                    </MultilineTitle>
                    <MultilineTitle position={TitlePosition.RIGHT} color={TitleColor.WHITE} className={styles.talkTitle}>
                        {talk.title}
                    </MultilineTitle>
                    {talk.subtitle ? (
                        <MultilineTitle position={TitlePosition.RIGHT} color={TitleColor.WHITE} className={styles.talkSubtitle}>
                            {talk.subtitle}
                        </MultilineTitle>
                    ) : null}
                    <div className={styles.buttons}>
                        {talk.eventUrl ? (
                            <LinkAsButton className={styles.button} color={ButtonColors.WHITE} to={talk.eventUrl} external>
                                KOSTENLOS ANMELDEN
                            </LinkAsButton>
                        ) : (
                            <LinkAsButton className={styles.button} color={ButtonColors.WHITE} to={'/about'}>
                                MEHR ZU DEN TALKS
                            </LinkAsButton>
                        )}
                        <LinkAsButton className={styles.button} color={ButtonColors.WHITE} to={`/talk/${talk.id}`}>
                            BESCHREIBUNG
                        </LinkAsButton>
                    </div>
                </div>
            </div>
        );
    }
};
