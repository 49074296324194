import React from 'react';
import { MultilineTitle, TitleColor, TitlePosition } from '../../../components/MultilineTitle';
import { ReactComponent as Location } from '../../../images/Location.svg';
import { ReactComponent as Mail } from '../../../images/Mail.svg';
import { ReactComponent as Phone } from '../../../images/Phone.svg';
import { ReactComponent as Parking } from '../../../images/Parking.svg';

import styles from './index.module.scss';

export const Contact: React.FC = () => {
    return (
        <>
            <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.sectionTitle}>
                Kontakt
            </MultilineTitle>
            <div className={styles.container}>
                <div className={styles.contactDetails}>
                    <b>cosee GmbH</b>
                    <div className={styles.spacer} />
                    <div>
                        <Location className={styles.svg} />
                        <span>
                            <a href="https://goo.gl/maps/S7kdFGKn3HP2" target="_blank" rel="noopener noreferrer nofollow" className={styles.externalLink}>
                                Mina-Rees-Straße 8<br />
                                64295 Darmstadt
                            </a>
                        </span>
                    </div>
                    <div className={styles.spacer} />
                    <div>
                        <Mail className={styles.svg} />
                        <span>
                            <a href="mailto:info@cosee.biz" className={styles.externalLink}>
                                info@cosee.biz
                            </a>
                        </span>
                    </div>
                    <div>
                        <Phone className={styles.svg} />
                        <span>
                            Tel.:{' '}
                            <a href="tel:+4961519574400" className={styles.externalLink}>
                                +49 6151 95744 00
                            </a>
                        </span>
                    </div>
                    <div className={styles.spacer} />
                    <div>
                        <Parking className={styles.svg} />
                        <span>Parkplätze auf Anfrage</span>
                    </div>
                </div>
            </div>
        </>
    );
};
