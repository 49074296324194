import React from 'react';

import styles from './index.module.scss';
import { MultilineTitle, TitlePosition, TitleColor } from '../MultilineTitle';

interface IHeroimageProps {
    image: string;
    title: string;
    subTitle?: string;
    alt?: string;
}

export const Heroimage: React.FC<IHeroimageProps> = ({ image, alt, title, subTitle }) => {
    return (
        <div className={styles.container}>
            <img src={image} alt={alt} className={styles.image} />
            <div className={styles.absolut}>
                <MultilineTitle position={TitlePosition.RIGHT} color={TitleColor.LIME_GREEN} className={styles.topTitle}>
                    {title}
                </MultilineTitle>
                {subTitle && (
                    <MultilineTitle position={TitlePosition.RIGHT} color={TitleColor.GRAY} className={styles.bottomTitle}>
                        {subTitle}
                    </MultilineTitle>
                )}
            </div>
        </div>
    );
};
