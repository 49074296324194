import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';
import { ITalk } from '../../../components/TalkInput/model';
import { useParams } from 'react-router';
import { MultilineTitle, TitleColor, TitlePosition } from '../../../components/MultilineTitle';
import { ButtonColors } from '../../../components/Button';
import { LinkAsButton } from '../../../components/LinkAsButton';
import { dateToDateString, dateToTimeString } from '../../../utils/datetime';
import { Newsletter } from '../../../components/Newsletter';
import { Tag } from '../../../components/Tag';
import classnames from 'classnames';
import { PageLoading } from '../../../components/PageLoading';
import { getTalk } from '../../../utils/api/talk';
import useScrollUp from '../../../utils/useScrollUp';
import { VideoPlayer } from '../../../components/VideoPlayer';

export const TalkDetail: React.FC = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [talk, setTalk] = useState<ITalk | null>(null);
    useScrollUp();

    useEffect(() => {
        if (id != null) {
            getTalk(id)
                .then((res) => {
                    const talk: ITalk = res.talk;
                    talk.talkDateTime = new Date(res.talk.talkDateTime);
                    setTalk(talk);
                    setLoading(false);
                })
                .catch((err) => console.error(err));
        }
    }, [id]);

    if (talk === null) {
        if (loading) {
            return <PageLoading />;
        } else {
            return <div>Talk with ID: {id} not found!</div>;
        }
    }

    let buttons: React.ReactNode[] = [];
    if (talk.slidesUrl != null) {
        buttons.push(
            <LinkAsButton external key="slides" color={ButtonColors.GRAY} to={talk.slidesUrl}>
                Folien
            </LinkAsButton>
        );
    }
    if (talk.zoomUrl != null) {
        buttons.push(
            <LinkAsButton external key="zoom" color={ButtonColors.GRAY} to={talk.zoomUrl}>
                Zoom Meeting
            </LinkAsButton>
        );
    }
    if (talk.sourceCodeUrl != null) {
        buttons.push(
            <LinkAsButton external key="code" color={ButtonColors.GRAY} to={talk.sourceCodeUrl}>
                Code
            </LinkAsButton>
        );
    }

    let video = null;
    if (talk.videoUrl != null && talk.videoUrl !== "") {
        if (talk.talkDateTime <= new Date()) {
            video = (
                <div className={styles.halfWidth}>
                    <VideoPlayer videoUrl={talk.videoUrl} previewImageUrl={talk.videoThumbnailImage} />
                </div>
            );
        } else {
            buttons.push(
                <LinkAsButton external key="lifestream" to={talk.videoUrl} color={ButtonColors.GRAY}>
                    Livestream
                </LinkAsButton>
            );
        }
    }

    const dateTitle = `[ ${dateToDateString(talk.talkDateTime)} | ${dateToTimeString(talk.talkDateTime)} Uhr ]`;

    return (
        <div>
            <h2 className={styles.dateTitle}>{dateTitle}</h2>
            <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.topTitle}>
                {talk.title}
            </MultilineTitle>
            {talk.subtitle ? (
                <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.subtitle}>
                    {talk.subtitle}
                </MultilineTitle>
            ) : null}
            <MultilineTitle color={TitleColor.GRAY} position={TitlePosition.LEFT} className={styles.speaker}>
                {talk.speaker.join(', ')}
            </MultilineTitle>
            {talk.tags === undefined || talk.tags.length === 0 ? null : (
                <div className={styles.tagContainer}>
                    {talk.tags.map((tag) => (
                        <Tag key={tag}>{tag}</Tag>
                    ))}
                </div>
            )}
            <div className={styles.talkDetailContainer}>
                <span
                    className={classnames(styles.talkDescription, {
                        [styles.fullWidth]: video == null,
                        [styles.halfWidth]: video != null,
                    })}
                    dangerouslySetInnerHTML={{ __html: talk.description }}
                />
                {video}
            </div>
            <div className={styles.buttonLine}>
                <div className={classnames(styles.buttons, { [styles.between]: buttons.length === 2 })}>{buttons}</div>
            </div>
            <Newsletter />
        </div>
    );
};
