import React from 'react';

interface IPrivacyLink {
    children: React.ReactNode;
    className?: string;
}

export const PrivacyLink: React.FC<IPrivacyLink> = ({ className, children }) => (
    <a
        className={className}
        style={{ textDecoration: 'underline', color: 'inherit' }}
        href="https://static.cosee.biz/documents/Privacy_Policy_Website_cosee_V1.01.pdf"
        target="_blank"
        rel="noopener noreferrer nofollow"
    >
        {children}
    </a>
);
