import { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import qs from 'querystringify';

function useQueryString(key: string, initialValue: string): [value: string, setValue: (v: string) => void];
function useQueryString<T>(key: string, initialValue: T, converter: (s: string) => T): [value: T, setValue: (v: T) => void];

function useQueryString<T>(key: string, initialValue: T, converter?: (s: string) => T): [value: T, setValue: (v: T) => void] {
    const { pathname, search } = useLocation();
    const params = qs.parse(search) as Record<string, string>;
    const [value, setValue] = useState<T>(params[key] && converter ? converter(params[key]) : initialValue);
    const onSetValue = useCallback(
        (newValue: T) => {
            setValue(newValue);
            const newUrl = pathname + qs.stringify({ ...qs.parse(search), [key]: newValue }, true);
            window.history.pushState({ path: newUrl }, '', newUrl);
        },
        [key, search, pathname]
    );
    return [value, onSetValue];
}

export default useQueryString;
