import React from 'react';
import { ReactComponent as CoseeLogo } from '../../../images/Logo.svg';
import { ReactComponent as CoseeLogoSmall } from '../../../images/Logo_Bildmarke.svg';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

export const Logo: React.FC = () => {
    return (
        <div className={styles.logo}>
            <Link to="/" className={styles.logoLink}>
                <CoseeLogo className={styles.logoSvg}></CoseeLogo>
                <CoseeLogoSmall className={styles.logoSvgSmall}></CoseeLogoSmall>
            </Link>
        </div>
    );
};
