import React, { useState } from 'react';
import { PrivacyLink } from '../PrivacyLink';
import { Button, ButtonColors } from '../Button';
import { ContentBlock } from '../ContentBlock';
import { Scheme } from '../ContentBlock/contentBlockUtils';
import { newsletterApi } from '../../utils/api/endpoints';

import styles from './index.module.scss';

export const Newsletter: React.FC = () => {
    const [mail, setMail] = useState<string>('');
    const [privacy, setPrivacy] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMail(event.currentTarget.value);
    };

    const togglePrivacy = () => {
        setPrivacy(!privacy);
    };

    const submitSubscription = () => {
        if (privacy === true && mail !== null && mail !== undefined && mail !== '') {
            const params: RequestInit = {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: mail }),
                method: 'POST',
            };

            fetch(newsletterApi, params)
                .then((response) => {
                    if (response.status === 200) {
                        setMail('');
                        setPrivacy(false);
                    } else {
                        response.text().then((body) => {
                            console.error(body);
                            try {
                                setError(JSON.parse(body).message);
                            } catch (e) {
                                setError(body);
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setError(JSON.parse(error).message);
                });
        }
    };

    let content;
    if (error !== null) {
        content = (
            <>
                <p>
                    <b>Das hat leider nicht geklappt:</b>
                </p>
                <p>{error}</p>
                <p>
                    Bitte melde dich direkt über Mailchimp bei unserem Newsletter an:{' '}
                    <a href="http://eepurl.com/gfOBjD" target="_blank" rel="noopener noreferrer nofollow" className={styles.link}>
                        TechTalk Newsletter
                    </a>
                </p>
            </>
        );
    } else {
        content = (
            <>
                <p>Verpasse keinen TechTalk mehr und bekomme die Infos zu aktuellen TechTalks direkt in dein Postfach.</p>
                <label htmlFor="mail" className={styles.text}>
                    Mail
                </label>
                <input className={styles.input} type="text" id="mail" name="mail" placeholder="info@cosee.biz" value={mail} onChange={changeEmail} />
                <div className={styles.disclaimer}>
                    <input type="checkbox" id="accept" className={styles.checkbox} checked={privacy} onChange={togglePrivacy} />
                    <label className={styles.text} htmlFor="accept">
                        Mit dem Absenden dieser Nachricht erklärst du dich damit einverstanden, dass wir deine Daten ausschließlich zu Kontaktzwecken nutzen und
                        speichern. Die Daten können auf Wunsch jederzeit gelöscht, widerrufen, eingesehen, transferiert und berichtigt werden. Nähere
                        Informationen dazu entnimm bitte unserer <PrivacyLink>Datenschutzerklärung</PrivacyLink>.
                    </label>
                </div>
                <Button color={ButtonColors.WHITE} className={styles.submitButton} onClick={submitSubscription}>
                    KOSTENLOS ANMELDEN
                </Button>
            </>
        );
    }

    return (
        <ContentBlock title="Melde dich zum Newsletter an!" scheme={Scheme.grayLimeGreen}>
            {content}
        </ContentBlock>
    );
};
