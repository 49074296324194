import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { ReactComponent as Play } from '../../images/Play.svg';

import styles from './index.module.scss';
import { PrivacyLink } from '../PrivacyLink';
import { Button, ButtonColors } from '../Button';

interface IVideoPlayerProps {
    videoUrl: string;
    previewImageUrl?: string;
}

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({ videoUrl, previewImageUrl }) => {
    const [accepted, accept] = useState<boolean>(false);

    if (accepted) {
        return (
            <div className={styles.playerWrapper}>
                <ReactPlayer width="100%" height="100%" url={videoUrl} controls={true} className={styles.reactPlayer} />
            </div>
        );
    } else {
        const style = previewImageUrl ? { backgroundImage: 'url(/' + previewImageUrl + ')', backgroundSize: 'contain' } : undefined;

        return (
            <div className={styles.playerWrapper} style={style}>
                <div className={styles.accept}>
                    <span className={styles.tos}>
                        Mit dem Laden des Videos erklärst du dich damit einverstanden, dass Daten an Dritte (YouTube/Vimeo) übermittelt werden und dass du
                        unsere <PrivacyLink className={styles.privacyLink}>Datenschutzerklärung</PrivacyLink> gelesen hast.
                    </span>
                    <Button color={ButtonColors.TRANSPARENT} onClick={() => accept(true)} className={styles.acceptButton}>
                        <Play className={styles.play} /> Akzeptieren
                    </Button>
                </div>
            </div>
        );
    }
};
